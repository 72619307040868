'use client';
import { useUpdateDeepCompareEffect } from '@edeeone/juan-core/hooks/useUpdateDeepCompareEffect';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
/**
 * Reset form when default values change.
 * @param formMethods Form methods from RHF.
 * @param defaultValues Default form values.
 * @param disableResetOnDefaultValuesChange Disable reset on default values change.
 * @param updatableFieldsOnNewDefaultValues Fields to update. When not defined, the entire form is reset.
 */
export const useResetFormOnDefaultValuesChange = ({ formMethods, defaultValues, disableResetOnDefaultValuesChange = false, updatableFieldsOnNewDefaultValues, }) => {
    useUpdateDeepCompareEffect(() => {
        if (!disableResetOnDefaultValuesChange) {
            const newValues = defaultValues;
            // NOTE: If updatableFieldsOnNewDefaultValues is not defined, we reset the entire form.
            if (!updatableFieldsOnNewDefaultValues?.length) {
                formMethods.reset(newValues, {
                    keepSubmitCount: true,
                    keepIsSubmitted: true,
                    keepErrors: true,
                    keepTouched: true,
                    keepDirty: true,
                    keepIsSubmitSuccessful: true,
                });
            }
            else {
                const currentValues = formMethods.getValues();
                const updatedValues = cloneDeep(currentValues);
                updatableFieldsOnNewDefaultValues.forEach((path) => {
                    const newValue = get(newValues, path);
                    const currentValue = get(currentValues, path);
                    // NOTE: When the original (state) value is null or undefined, we do not update.
                    if (currentValue == null) {
                        return;
                    }
                    const parentPath = path.split('.').slice(0, -1).join('.');
                    // NOTE: When the parent object does not exist (e.g. billingAddress is null), we ignore the update.
                    if (parentPath && !has(currentValues, parentPath)) {
                        return;
                    }
                    if (!isEqual(currentValue, newValue)) {
                        set(updatedValues, path, newValue);
                    }
                });
                formMethods.reset(updatedValues, {
                    keepSubmitCount: true,
                    keepIsSubmitted: true,
                    keepErrors: true,
                    keepTouched: true,
                    keepDirty: true,
                    keepIsSubmitSuccessful: true,
                });
            }
            const touchedCount = Object.keys(formMethods.formState.touchedFields).length;
            // When the form has already been touched, we trigger validation again after changing the default values.
            if (touchedCount && formMethods.formState.isSubmitted) {
                formMethods.trigger();
            }
        }
    }, [defaultValues, updatableFieldsOnNewDefaultValues]);
};
